import React from 'react';
import '../../../styles/Overview.css'

function Overview() {
    return (
        <div className='Overview'>
            <div className="container">
                <div className="row">
                    <div className="left-col">
                        <h2 data-aos="fade" data-aos-duration="1000">About Us</h2>
                        <p data-aos="fade" data-aos-duration="1000">
                            At Kaanthi Herbal Clinic, we believe in unlocking your natural radiance through the power of Ayurveda and herbal remedies. Our team of expert herbalists in Calicut crafts personalized solutions for healthy, glowing skin and luscious hair.
                            <br></br>
                            <br></br>
                            We offer a holistic approach to beauty, combining natural skincare & haircare products made with nature's finest ingredients to nourish and revitalize without harsh chemicals. Our experts create personalized routines tailored to your unique needs and desired results, while also sharing knowledge on natural remedies from the ancient wisdom of Ayurveda to enhance your skin and hair health.
                            <br></br>
                            <br></br>
                            Kaanthi is dedicated to providing a sustainable and empowering path to wellness. Let us guide you on a journey towards a healthier, more radiant you. Visit us today and experience the transformative power of nature's touch.
                        </p>
                    </div>
                    <div className="right-col" data-aos="fade" data-aos-duration="1000">
                        <div className='img-container'>
                            <img src="img/Kaanthi Logo-01.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Overview