import React from 'react';
import '../../../styles/SpaBenefits.css';
import gsap from "gsap";

import { useGSAP } from "@gsap/react";

import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(useGSAP, ScrollTrigger);


function SpaBenefits() {

  useGSAP(() => {
    gsap.from(".SpaBenefits .img-container img", {
      scrollTrigger: {
        trigger: " .SpaBenefits .img-container img",
        toggleActions: "restart pause resume pause",
        start: 'top 60%',
        end: "bottom 10%",
        scrub: 2,
        // markers: true
      },
      yPercent: "-15",
      duration: 2,

    })


  }, []);
  return (
    <div className='SpaBenefits'>
      <div className="container">
        <div className="row">
          <div className="left-col">
            <h2 data-aos="fade" data-aos-duration="1000">Benefits of SPA</h2>
            <hr data-aos="fade" data-aos-duration="1000"/>
            <ul>
              <li data-aos="fade" data-aos-duration="1000"><i class="fa-solid fa-square-check"></i><p><b>Natural Rejuvenation: </b> Experience the revitalizing effects of natural ingredients and holistic therapies, promoting overall well-being.</p></li>
              <li data-aos="fade" data-aos-duration="1000"><i class="fa-solid fa-square-check"></i><p><b>Personalized Care: </b> Receive customized treatments tailored to your unique hair and skin concerns, addressing specific needs effectively.</p></li>
              <li data-aos="fade" data-aos-duration="1000" ><i class="fa-solid fa-square-check"></i><p><b>Stress Relief: </b> Indulge in relaxing treatments that not only enhance external beauty but also promote inner peace and relaxation.</p></li>
              <li data-aos="fade" data-aos-duration="1000"><i class="fa-solid fa-square-check"></i><p><b>Healthy Scalp and Hair: </b> Restore scalp health, strengthen hair follicles, and promote hair growth with nourishing Ayurvedic treatments.</p></li>
            </ul>

          </div>
          <div className="right-col">
            <div className="img-container">
              <img src="img/benefits-bg.jpg" alt="benefits of spa " />
            </div>

          </div>
        </div>

      </div>
    </div>
  )
}

export default SpaBenefits