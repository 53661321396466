import React from 'react';
import '../../../styles/Herosection.css';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(useGSAP, ScrollTrigger);


function Herosection() {

  useGSAP(() => {
    gsap.from(".herosection-bg", {
      scrollTrigger: {
        trigger: ".herosection-bg",
        toggleActions: "restart pause resume pause",
        start: 'top',
        end: "bottom",
        scrub: 2,
        // markers: true
      },
      yPercent: "-30",
      duration: 3,

    })

  }, []);
  return (
    <div className='Herosection'>
      <div className="container">
        <div className="content">
          <h1 data-aos="fade"data-aos-duration="1000"><span>Kaanthi Ayurveda:</span> Where Tradition Meets Innovation for Skin and Hair Wellness</h1>
          <p data-aos="fade"data-aos-duration="1000">Welcome to Kaanthi Ayurveda, where ancient wisdom meets modern skincare innovation. Our sanctuary offers personalized treatments crafted to nurture your skin and hair naturally, revealing your inner radiance. Join us on a journey to embrace holistic beauty and experience the transformative power of Ayurvedic wellness.</p>
        </div>
      </div>
      <div className="herosection-bg">
      </div>
      <div className="mandala-art">
        <img src="img/mandala-art.png" alt="" />
      </div>
      <div className="mandala-art-bottom">
        <img src="img/mandala-art.png" alt="" />
      </div>
    </div>
  )
}

export default Herosection