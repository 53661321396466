import React from 'react';
import Navbar from '../Components/Common/Navbar/Navbar';
import Herosection from '../Components/HomePage/Herosection/Herosection';
import ServiceIntro from '../Components/HomePage/ServiceIntro/ServiceIntro';
import WhyChooseUs from '../Components/HomePage/WhyChooseUs/WhyChooseUs';
import Appointment from '../Components/HomePage/Appointment/Appointment';
import SpaBenefits from '../Components/HomePage/SpaBenefits/SpaBenefits';
import Footer from '../Components/Common/Footer/Footer';


function Home() {
  return (
    <div className='Home'>
      <Navbar/>
      <Herosection/>
      <ServiceIntro/>
      <WhyChooseUs/>
      {/* <Appointment/> */}
      <SpaBenefits/>
      <Footer/>
    </div>
  )
}

export default Home