import React from 'react'
import '../../../styles/Mission.css'

function Mission() {
    return (
        <div className='Mission'>
            <div className="container">
                <div className="row">
                    <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-delay="100">
                        <div className="content">
                            <img src="img/target.png" alt="mission" />
                            <h3>Mission</h3>
                            <p>  At Kaanthi Herbal Clinic, our mission is to provide innovative, safe, and effective natural treatments for skin and hair care. We strive to harness the power of nature to heal and enhance, offering personalized care that respects both individual needs and the environment. Through our expert knowledge and natural therapies, we aim to restore health and confidence in our clients, helping them achieve their best natural beauty.</p>
                        </div>
                       
                    </div>

                    <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-delay="300">
                        <div className="content">
                            <img src="img/eye.png" alt="vision" />
                            <h3>Vision</h3>
                            <p> Our vision is to be the leader in natural skin and hair care solutions, recognized for our commitment to sustainability, wellness, and the highest standards of customer satisfaction. We aspire to grow our reach, influencing the beauty industry by promoting treatments that are both effective and ecologically responsible. At Kaanthi Herbal Clinic, we envision a future where natural beauty is accessible to all, inspiring a healthier, more vibrant community.</p>

                        </div>
                        
                    </div>
                    <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-delay="500">
                        <div className="content">
                            <img src="img/diamond.png" alt="values" />
                            <h3>Our Values</h3>
                            <p> At Kaanthi Herbal Clinic, we are committed to natural wellness, holistic care, and sustainability. We prioritize compassionate expertise, personalized attention, and integrity in all our services. We focus on educating our clients, using evidence-based innovations, and collaborating with the community to deliver top-quality skincare and haircare while positively impacting the world.</p>
                        </div>
                        
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Mission