import React from 'react';
import '../../../styles/QuoteSection.css';
import gsap from "gsap";

import { useGSAP } from "@gsap/react";

import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(useGSAP, ScrollTrigger);


function QuoteSection() {

  useGSAP(() => {
    gsap.from(".QuoteSection .img-container img", {
      scrollTrigger: {
        trigger: " .QuoteSection .img-container img",
        toggleActions: "restart pause resume pause",
        start: 'top 60%',
        end: "bottom 10%",
        scrub: 2,
        // markers: true
      },
      yPercent: "-15",
      duration: 2,

    })


  }, []);
  return (
    <div className='QuoteSection'>
      <div className="container">
        <div className="row">
          <div className="left-col">
            <p data-aos="fade" data-aos-duration="1000">"Ayurveda is the science of life that teaches us how to live in harmony with nature and the world around us."</p>
            <strong data-aos="fade" data-aos-duration="1000">- Dr Robert Svoboda.</strong>

          </div>
          <div className="right-col">
            <div className="img-container">
              <img src="img/quote-section-bg.jpg" alt="appointment " />
            </div>

          </div>
        </div>

      </div>
    </div>
  )
}

export default QuoteSection