import React from 'react';
import '../../../styles/WhyChooseUs.css';

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);

function WhyChooseUs() {

    useGSAP(() => {
        gsap.fromTo(".leaf-elements img", {
            scrollTrigger: {
                trigger: ".leaf-elements img",
                toggleActions: "restart pause resume pause",
                start: 'top 100%',
                end: "bottom 10%",
                scrub: 2,
                // markers: true
            },
            yPercent: "-50",
            xPercent:'-50',
            duration: 2,
            opacity: 0,
            rotate: 0,

        }, {
            scrollTrigger: {
                trigger: ".leaf-elements img",
                toggleActions: "restart pause resume pause",
                start: 'top 100%',
                end: "bottom 10%",
                scrub: 2,
                // markers: true
            },
            yPercent: "0",
            xPercent:'0',

            duration: 2,
            opacity: 0.5,
            rotate: 45,

        })
        gsap.fromTo(".leaf-elements-right img", {
            scrollTrigger: {
                trigger: ".leaf-elements-right img",
                toggleActions: "restart pause resume pause",
                start: 'top 80%',
                end: "bottom 80%",
                scrub: 2,
                // markers: true
            },
            yPercent: "50",
            xPercent:'50',
            duration: 2,
            opacity: 0,
            rotate: 0,

        }, {
            scrollTrigger: {
                trigger: ".leaf-elements-right img",
                toggleActions: "restart pause resume pause",
                start: 'top 80%',
                end: "bottom 80%",
                scrub: 2,
                // markers: true
            },
            yPercent: "0",
            xPercent:'0',

            duration: 2,
            opacity: 0.4,
            rotate: 45,

        })


    }, []);
    return (
        <div className='WhyChooseUs'>
            <div className="container">
                <div className="top">
                    <h2 data-aos="fade"data-aos-duration="1000">Why Choose Us</h2>
                    <p data-aos="fade"data-aos-duration="1000">Discover why our spa stands out as your premier destination for relaxation and rejuvenation. From our expert therapists and tranquil ambiance to our personalized approach and commitment to excellence, we offer an unparalleled experience tailored to your wellness needs.</p>
                </div>
                <div className="row">
                    <div className="card"data-aos="fade"data-aos-duration="1000">
                        <img src="img/why-choose-us/cream.png" alt="skin" />
                        <h3>Expert Skin and Hair Care</h3>
                        <p>Trust in the expertise of our seasoned practitioners dedicated to delivering authentic treatments tailored specifically for your skin and hair needs, rooted in centuries-old wisdom.</p>
                    </div>
                    <div className="card"data-aos="fade"data-aos-duration="1000">
                      <img src="img/why-choose-us/stress-relief.png" alt="stress free" />
                        <h3>Stress-Free Environment</h3>
                        <p>Experience relaxation in our serene clinic environment, where every session is crafted not only to enhance your outer beauty but also to provide solace for your mind, easing stress.</p>
                    </div>
                    <div className="card"data-aos="fade"data-aos-duration="1000">
                        <img src="img/why-choose-us/bulb.png" alt="skin and hair" />
                        <h3>Comprehensive Solutions for Skin and Hair</h3>
                        <p>Whether you're grappling with hair loss, skin concerns, or simply seeking rejuvenation, our clinic offers a diverse array of specialized treatments to comprehensively address your skin and hair needs.</p>
                    </div>
                    <div className="card"data-aos="fade"data-aos-duration="1000">
                        <img src="img/why-choose-us/customer-support (1).png" alt="continuous support" />
                        <h3>Continuous Support</h3>
                        <p>Receive ongoing support and guidance from our knowledgeable practitioners, committed to assisting you in achieving and maintaining your desired skin and hair goals with personalized attention.</p>
                    </div>
                    <div className="card"data-aos="fade"data-aos-duration="1000">
                        <img src="img/why-choose-us/users-avatar.png" alt="customer" />
                        <h3>Customer-Centric Care</h3>
                        <p>Your satisfaction and well-being are paramount at Kaanthi Skin and Hair Clinic. We focus on fostering enduring relationships with our clients, delivering attentive care tailored to your skin and hair journey for a fulfilling experience.</p>
                    </div>
                    <div className="card"data-aos="fade"data-aos-duration="1000">
                        <img src="img/why-choose-us/guarantee.png" alt="gentle care" />
                        <h3>Safe and Gentle Care</h3>
                        <p>Rest easy knowing that our treatments are gentle, non-invasive, and devoid of harsh chemicals, offering safe and effective solutions suitable for all skin and hair types, promoting natural beauty and health.</p>
                    </div>

                </div>
            </div>
            <div className="leaf-elements">
                <img src="img/Acacia_concinna_Blanco2.374-cropped-removebg-preview.png" alt="" />

            </div>
            <div className="leaf-elements-right">
                <img src="img/_Downloader.la_-661fe1e920e34-removebg-preview.png" alt="" />

            </div>

        </div>
    )
}

export default WhyChooseUs