import React from 'react';
import '../../../styles/ServiceIntro.css'
import { Link } from 'react-router-dom';

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(useGSAP, ScrollTrigger);

function ServiceIntro() {


  useGSAP(() => {
    gsap.fromTo(".leaf-element img", {
      scrollTrigger: {
        trigger: ".leaf-element img",
        toggleActions: "restart pause resume pause",
        start: 'top 60%',
        end: "bottom 10%",
        scrub: 2,
        // markers: true
      },
      yPercent: "-50",
      duration: 2,
      opacity: 0,
      rotate: 190,

    }, {
      scrollTrigger: {
        trigger: ".leaf-element img",
        toggleActions: "restart pause resume pause",
        start: 'top 60%',
        end: "bottom 10%",
        scrub: 2,
        // markers: true
      },
      yPercent: "0",
      duration: 2,
      opacity: 0.5,
      rotate: 145,



    })
    gsap.fromTo(".leaf-element-scnd img", {
      scrollTrigger: {
        trigger: ".leaf-element-scnd img",
        toggleActions: "restart pause resume pause",
        start: 'top 100%',
        end: "bottom 70%",
        scrub: 2,
        // markers: true
      },
      yPercent: "0",
      xPercent:"100",
      duration: 2,
      opacity: 0,
      rotate: 10,

    }, {
      scrollTrigger: {
        trigger: ".leaf-element-scnd img",
        toggleActions: "restart pause resume pause",
        start: 'top 100%',
        end: "bottom 70%",
        scrub: 2,
        // markers: true
      },
      yPercent: "-10",
      xPercent:"0",

      duration: 2,
      opacity: 0.5,
      rotate: -10,



    })

  }, []);
  return (
    <div className='ServiceIntro'>
      <div className="container">
        <div className="content">
          <h2 data-aos="fade"data-aos-duration="1000">Revitalize Your Glow: Choose from Our Range of Ayurvedic Spa Services</h2>
          <p data-aos="fade"data-aos-duration="1000"> Discover the essence of Ayurvedic wellness through our curated selection of transformative services. Delve into a world where ancient wisdom meets modern luxury, as our expert practitioners guide you on a journey to radiant beauty and inner harmony. From revitalizing skincare rituals to deeply nourishing hair treatments, each service is crafted to nurture your unique beauty needs. Explore our offerings below and embark on a path to holistic rejuvenation.</p>
          <Link data-aos="fade"data-aos-duration="1000" to={'/spa-services'}>Explore Our Services</Link>
          
        </div>
      </div>
      <div className="leaf-element">
        <img src="img/service-intro-leaf.png" alt="leaf" />
      </div>
      <div className="leaf-element-scnd">
        <img src="img/service-intro-leaf-2.png" alt="leaf" />
      </div>
    </div>
  )
}

export default ServiceIntro