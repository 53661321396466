import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import BannerSection from '../Components/Common/BannerSection/BannerSection'
import BannerData from '../Components/Helpers/BannerData'
import Overview from '../Components/AboutPage/Overview/Overview'
import Mission from '../Components/AboutPage/Mission/Mission'
import OverviewV2 from '../Components/AboutPage/OverviewV2/OverviewV2'
import QuoteSection from '../Components/AboutPage/QuoteSection/QuoteSection'
import Footer from '../Components/Common/Footer/Footer'

function About() {
    const { AboutH2, AboutImg } = BannerData
    return (
        <div className='About'>
            <Navbar />
            <BannerSection h2={AboutH2} imgUrl={AboutImg} />
            <Overview/>
            {/* <OverviewV2/> */}
            <Mission/>
            <QuoteSection/>
            <Footer/>
        </div>
    )
}

export default About