import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import SpaServices from './Pages/SpaServices';
import ScrollToTop from './Components/Common/ScrollToTop/ScrollToTop';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
import PreLoader from './Components/Common/PreLoader/PreLoader';
import Whatsapp from './Components/Common/Whatsapp_btn/Whatsapp';


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {

      // Simulate a delay
      await new Promise(resolve => setTimeout(resolve, 2600));
    
      if (document.readyState !== 'loading') {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
      <Whatsapp/>
      <PreLoader loading={loading}/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/spa-services' element={<SpaServices />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/contact' element={<Contact />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
