

const BannerData = {
    AboutH2: "About",
    AboutImg: "img/banner-section-bg.jpg",

    SpaServicesH2: "Spa Services",
    SpaServicesImg: "img/banner-section-bg.jpg",

    GalleryH2: "Gallery",
    GalleryImg: "img/banner-section-bg.jpg",


    ContactH2: "Contact",
    ContactImg: "img/banner-section-bg.jpg",
}

export default BannerData