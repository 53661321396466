import React, { useState } from 'react';
import '../../../styles/ServiceDetails.css'

function ServiceDetails() {
  const [selectedButton, setSelectedButton] = useState('SPA MASSAGE');

  const handleButtonClick = (button) => {
    setSelectedButton(button);

  }
  return (
    <div className='ServiceDetails'>
      <div className="container">
        <div className="buttons"data-aos="fade"data-aos-duration="1000">
          {/* Buttons to switch between contents */}
          <button onClick={() => handleButtonClick('SPA MASSAGE')} className={selectedButton === 'SPA MASSAGE' ? 'active' : ''}>
            <img src="img/spa-services/facial-massage.png" alt="facial massage" />
            <p>SPA MASSAGE</p>
          </button>
          <button onClick={() => handleButtonClick('SKIN TREATMENTS')} className={selectedButton === 'SKIN TREATMENTS' ? 'active' : ''}>
            <img src="img/spa-services/moisturizing.png" alt="skin treatment" />
            <p>SKIN TREATMENTS</p>
          </button>
          <button onClick={() => handleButtonClick('FACE THERAPY(Mukha kanthi)')} className={selectedButton === 'FACE THERAPY(Mukha kanthi)' ? 'active' : ''}>
            <img src="img/spa-services/skin-care.png" alt="skin-care" />

            <p>FACE THERAPY (Mukha kanthi)</p>
          </button>
          <button onClick={() => handleButtonClick('FOOT CARE')} className={selectedButton === 'FOOT CARE' ? 'active' : ''}>
            <img src="img/spa-services/foot.png" alt="foot care" />
            <p>FOOT CARE</p>
          </button>
          <button onClick={() => handleButtonClick('HAND CARE(Hasta Pooja)')} className={selectedButton === 'HAND CARE(Hasta Pooja)' ? 'active' : ''}>
            <img src="img/spa-services/hand.png" alt="HAND CARE" />
            <p>HAND CARE (Hasta Pooja)</p>
          </button>
          <button onClick={() => handleButtonClick('HAIR CARE')} className={selectedButton === 'HAIR CARE' ? 'active' : ''}>
            <img src="img/spa-services/hair.png" alt="" />
            <p>HAIR CARE</p>
          </button>
          <button onClick={() => handleButtonClick('SKIN AND HAIR CARE FOR BRIDE AND GROOM')} className={selectedButton === 'SKIN AND HAIR CARE FOR BRIDE AND GROOM' ? 'active' : ''}>
            <img src="img/spa-services/skin-cell.png" alt="" />
            <p>SKIN AND HAIR CARE FOR BRIDE AND GROOM</p>
          </button>
        </div>
        <div className='content'data-aos="fade"data-aos-duration="1000">
          {/* Conditional rendering based on the selectedButton state */}
          {selectedButton === 'SPA MASSAGE' && <SpaMassage />}
          {selectedButton === 'SKIN TREATMENTS' && <SkinTreatments />}
          {selectedButton === 'FACE THERAPY(Mukha kanthi)' && <FaceTherapy />}
          {selectedButton === 'FOOT CARE' && <FootCare />}
          {selectedButton === 'HAND CARE(Hasta Pooja)' && <HandCare />}
          {selectedButton === 'HAIR CARE' && <HairCare />}
          {selectedButton === 'SKIN AND HAIR CARE FOR BRIDE AND GROOM' && <SkinHair />}
        </div>

      </div>
    </div>
  )
}
// Example content components
function SpaMassage() {
  return <div >
    <p>Unwind and rejuvenate with spa massages, where expert therapists skillfully knead away stress and tension, leaving you feeling blissfully relaxed and revitalized. Treat yourself to a tranquil escape, where each soothing stroke and gentle touch melts away the cares of the day, leaving you feeling renewed and refreshed.</p>
    <img src="img/spa-services/Right-time-for-massage-blog.jpg" alt="spa massage" />
    <h2>1. Aromatherapy</h2>
    <p>Indulge in the Essence of Relaxation with Aromatherapy.
      A holistic journey towards wellness with our revitalizing aromatherapy sessions
      where the fragrance of nature intertwines with the tranquility of your mind, body,
      and spirit. Each aromatic essential oil has its unique properties, meticulously
      created for blissful serenity. Immerse yourself in a 60 or 90-minute soothing
      massage session and get transported to the realm of relaxation. The sessions are
      tailored to melt away stress, enhance circulation, and promote restful sleep.
      Aromatherapy isn't just about the massage; it's a holistic approach to well-being.
      The carefully curated blend of essential oils activates your olfactory senses,
      stimulating specific areas of the brain associated with emotions. Experience a
      profound sense of calm as anxiety fades away, and stress and depression ease.
      Following your massage, indulge in a rejuvenating lukewarm bath, allowing the
      essence of the oils to linger on your skin. Join us on a voyage of relaxation and
      rejuvenation. Let aromatherapy awaken your senses, renew your spirit, and
      elevate your well-being.
    </p>
    <h2>2. Swedish Massage</h2>
    <p>Experience tranquility and rejuvenation.
      Immerse yourself in the exquisite art of Swedish massage, a sublime experience
      that cocoons you in serenity while soothing your body, calming your mind, and
      revitalizing your spirit.
      <br />
      <br />
      Renowned for its gentle yet highly effective techniques, Swedish massage is
      tailored to cater to your unique needs, ensuring a personalized journey of
      unmatched comfort and bliss. Let our skilled therapists guide you through a path
      of profound relaxation as they expertly apply long, flowing strokes, gently
      kneading away tension and melting stress from your muscles.
      Each movement is meticulously crafted to promote circulation, alleviate muscle
      soreness, and enhance overall well-being. Join us for either a 60-minute or
      90-minute session of Swedish massage, followed by a comforting lukewarm bath,
      and embark on a transformative journey toward holistic wellness and inner
      harmony.</p>
    <h2>3. Deep tissue massage</h2>
    <p>Discover Deep Healing with Deep Tissue Massage.Step into a world of profound rejuvenation and therapeutic relief, where the
      transformative art of deep tissue massage awaits to revitalize your body and
      promote profound well-being.
      Deep tissue massage targets the deeper layers of muscle and connective tissue,
      offering unparalleled relief from chronic tension, muscle stiffness, and
      discomfort. Whether you're seeking relief from persistent pain or simply craving a
      deeper level of relaxation, this modality is highly effective and tailored to meet
      your needs. Our skilled therapists specialize in the art of deep tissue massage,
      utilizing a blend of firm pressure and deliberate strokes to release tension,
      improve flexibility, and restore optimal muscle function. Whether you're an athlete
      or fitness enthusiast striving for peak performance or someone seeking respite
      from the stresses of everyday life, deep tissue massage provides a sanctuary
      where healing begins from within. Indulge in a session of deep tissue massage
      for either 60 or 90 minutes, followed by a soothing hot water bath, and embark on
      a journey toward renewed vitality and holistic well-being. Join us in the embrace
      of deep tissue massage and experience the transformative power of therapeutic
      touch.</p>
  </div>;
}

function SkinTreatments() {
  return <div>
    <h2>1. Tejaswini</h2>
    <p>
      Introducing Tejaswini: The Ultimate Full Body Skin Care Experience by Kaanthi
      Indulge in a luxurious journey of skin rejuvenation with Tejaswini, our signature full-body skin
      care treatment. Crafted by Kaanthi, this holistic experience combines gentle massage
      techniques with nourishing herbal and fruit scrubs, culminating in a transformative massage with
      Njavara.
      <br></br>
      <br></br>
      Your Tejaswini experience begins with a soothing massage, designed to relax your body and
      prepare your skin for the journey ahead. Next, indulge in the exfoliating benefits of our herbal or
      fruit scrub, gently removing dead skin cells and unveiling a radiant complexion.
      The highlight of Tejaswini is the Njavara massage, featuring a blend of skin-revitalizing herbs
      that deeply nourish and rejuvenate your skin. This ancient practice not only enhances circulation
      but also offers anti-aging properties, leaving your skin with a youthful glow.
      Experience the complete revitalization of your skin with Tejaswini by Kaanthi. Treat yourself to
      this luxurious ritual and discover the radiant, youthful complexion you deserve.
      Unveil your skin's natural beauty with Tejaswini - where holistic care meets timeless
      rejuvenation.

    </p>
    <h2>2. Body Scrub and wrap</h2>
    <p>
      Reveal Your Skin's Natural Radiance with Our Body Scrub and Wrap
      Indulge in a luxurious experience of skin renewal with our exquisite body scrub and wrap
      treatments. Designed to revitalize and nourish your skin from head to toe, these pampering
      rituals offer a blissful escape from the stresses of daily life.
      Begin your journey with our invigorating body scrub, meticulously crafted with natural
      ingredients to gently exfoliate and remove dead skin cells revealing a soft, smooth texture that
      glows with health and vitality.
      <br></br>
      Next, surrender to the cocooning comfort of our body wrap, enveloping your senses in a
      soothing embrace of nourishing ingredients. Let the rich, hydrating formula penetrate deeply,
      replenishing moisture and restoring balance to your skin.
      Reveal your skin's natural radiance with our body scrub and wrap - where pampering meets
      perfection.

    </p>
  </div>
}

function FaceTherapy() {
  return <div>
    <p>Embrace Natural Beauty with Our Three Exquisite Face Treatments</p>
    <p>
      At Kaanthi, we are dedicated to harnessing the power of nature to illuminate your skin's true
      radiance. Our three meticulously crafted face treatments are designed with pure ingredients and
      without chemicals, offering a truly indulgent experience.
      <br></br>
      <br></br>
      Experience the essence of natural beauty with our range of face care options, tailored to suit
      your needs and preferences. Choose from our three exquisite treatments, each offering its
      unique blend of nourishment and rejuvenation:

    </p>
    <h2>1. Clean Up Delight</h2>
    <p>Refresh and revitalize your skin with our Clean Up Delight treatment. This quick and effective
      session includes gentle exfoliation, soothing massage strokes, and a nourishing pack, leaving
      your skin radiant and refreshed.</p>
    <h2>2. Complete Face Rejuvenation</h2>
    <p>Treat yourself to a luxurious journey of relaxation and rejuvenation with our Complete Face
      Rejuvenation therapy. Experience a comprehensive blend of massages, exfoliation, soothing
      roller massages, customized packs, and special care for your skin and eyes, for a truly radiant
      complexion.</p>
    <h2>
      3. Anti-Aging Bliss
    </h2>
    <p>
      Turn back the hands of time with our Anti-Aging Bliss treatment. Featuring the star ingredient
      Njavara, this indulgent therapy combats signs of aging while nourishing and pampering your
      skin. Enjoy traditional techniques of massage, exfoliation, and nourishing packs, followed by the
      ultimate indulgence of a soothing Njavara Kizhi massage.
      <br></br>
      <br></br>
      Experience the transformative power of natural skincare with Kaanthi. Choose the perfect face
      care treatment for you and unveil your skin's natural radiance today.
    </p>
  </div>;
}
function FootCare() {
  return <div>
    <h2>Foot Reflexology</h2>
    <p>Step into Relaxation with Our Foot Reflexology Treatments
      Escape into a world of tranquility and rejuvenation with our soothing foot reflexology treatments.
      Designed to revitalize and restore balance to your body, these sessions offer a blissful respite
      from the demands of everyday life.
      <br></br>
      <br></br>
      Choose from our 30-minute or 45-minute foot reflexology sessions and indulge in a pampering
      experience that will leave you feeling refreshed and rejuvenated.
      <br></br>
      <br></br>
      In just 30 minutes:
      Feel the tension melt away as our skilled therapists apply gentle pressure to specific points on
      your feet, stimulating reflex areas that correspond to different organs and systems in your body.
    </p>
    <p>
      Experience a sense of deep relaxation as energy blockages are released, promoting overall
      well-being and harmony.
      <br></br>
      <br></br>
      In 45 minutes:
      Take your relaxation to the next level with our extended 45-minute foot reflexology session.
      Allow yourself to sink into a state of bliss as our therapists work their magic, targeting key reflex
      points to alleviate tension, improve circulation, and enhance your body's natural healing abilities.
      Feel stress and fatigue melt away as you drift into a state of complete relaxation.
      Experience the therapeutic benefits of foot reflexology and treat yourself to a moment of pure
      indulgence. Book your session today and step into a world of relaxation and renewal with us.
      Unwind, rejuvenate, and restore balance to your body and soul with our foot reflexology
      treatments. Your journey to relaxation awaits.

    </p>
    <h2>Pada pooja</h2>
    <p>
      Experience Pure Bliss with Our Herbal Foot Care Treatments
      <br></br>
      <br></br>
      At Kaanthi, we understand the importance of nurturing your feet, which is why we offer two
      luxurious herbal foot care treatments designed to pamper and revitalize.
    </p>
    <h3>1. Herbal Foot Revive</h3>
    <p>Treat your feet to a rejuvenating experience with our Herbal Foot Care - the stress melts away
      as we exfoliate your feet, leaving them silky smooth and refreshed. Our gentle cleansing
      ensures your feet are clean and revitalized, while a soothing massage adds an extra layer of
      relaxation.
    </p>
    <h3>2. Ultimate foot rejuvenation</h3>
    <p>
      Experience all the benefits of our herbal foot revive treatment, enhanced with the addition of a
      luxurious foot wrap and extra massage. Feel your feet transformed into baby-soft perfection as
      we lavish them with care and attention.
      <br></br>
      <br></br>
      Whether you choose our Basic or Deluxe treatment, you're guaranteed to leave feeling
      refreshed, revitalized, and ready to take on the world.
      Treat your feet to the care they deserve with our Herbal Foot Care treatments at Kaanthi.
    </p>

  </div>
}
function HandCare() {
  return <div>
    <p>Experience Pure Comfort with Our Herbal Hand Care Treatments
      <br></br>
      <br></br>
      At Kaanthi, we understand the importance of nurturing your hands, which is why we offer two
      luxurious herbal hand care treatments designed to pamper and revitalize.
    </p>
    <h2>1. Herbal Hand renewal</h2>
    <p>Feel the stress melt away as we exfoliate your hands, leaving them silky smooth and refreshed.
      Our gentle cleansing ensures your hands are clean and revitalized, while a soothing massage
      adds an extra layer of relaxation.</p>
    <h2>2. Premium herbal hand rejuvenation</h2>
    <p>Experience all the benefits of our Basic treatment, enhanced with the addition of a luxurious
      hand wrap and extra massage. Feel your hands transformed into velvety soft perfection as we
      lavish them with care and attention.
      <br></br>
      <br></br>
      You're guaranteed to leave feeling refreshed, revitalized, and ready to embrace the world with
      beautifully pampered hands.
      <br></br>
      <br></br>
      Treat your hands to the care they deserve with our Herbal Hand Care treatments at Kaanthi.</p>

  </div>
}
function HairCare() {
  return <div>
    <h2>1. Kesakanthi</h2>
    <p>"Embark on a Luxurious Herbal Hair Spa Experience"
      <br></br>
      <br></br>
      Treat yourself to the pinnacle of hair rejuvenation with our Herbal Hair Spa. Immerse yourself in
      the luxury of a soothing hot oil massage, complemented by the application of a revitalizing
      herbal pack and nourishing natural protein treatment. Finish your journey with a refreshing wash
      using herbal decoction, leaving your hair revitalized, refreshed, and luxuriously pampered.</p>
    <h2>2. Hot oil head massage</h2>
    <p>Indulge in the ultimate relaxation with our hot oil hair massage. Feel tension melt away as warm,
      nourishing oils are expertly massaged into your scalp, promoting deep relaxation and leaving
      your hair silky smooth and rejuvenated.
    </p>
    <h2>3. Anti dandruff treatments</h2>
    <p>Experience the Herbal Anti-Dandruff Therapy at Kaanthi, a meticulously crafted three-session
      process tailored to your individual needs. Our doctor will determine the intervals between each
      session based on the severity of your condition. Through a blend of therapies including hot oil
      massage, thalapothichil, and dhara, all using Ayurvedic ingredients free from chemicals, we
      ensure a smooth, dandruff-free scalp. Additionally, you'll receive personalized lifestyle advice to
      prevent future occurrences, leaving you with long-lasting relief and confidence.
    </p>
    <h2>4. Hair fall treatment</h2>
    <p>Embark on a comprehensive 5-session herbal therapy journey at Kaanthi, where tailored
      Ayurvedic treatments address individual needs. Before commencement, thorough blood tests
      assess key factors, complemented by a scalp examination and consideration of overall health
      by our expert doctors. With a variety of Ayurvedic treatments and internal medication
      recommendations, we aim not only for improved hair growth but also for vibrant, healthy, and
      rejuvenated locks, ensuring a bounce that speaks of vitality and well-being.</p>
  </div>
}
function SkinHair() {
  return <div>
    <p>
      Experience Ultimate Skin and Hair Care for the Bride and Groom at Kaanthi
      At Kaanthi, we understand the significance of looking and feeling your best on your special day.
      That's why we offer two exclusive packages for brides and grooms: a single-day session or a
      luxurious three-day ultimate bliss experience. The choice is yours to make.
      Our tailor-made packages include a variety of indulgent treatments such as massages,
      exfoliation, kizhi, wraps, hair treatments, and specialized face care. Each treatment is
      meticulously designed to enhance the beauty of your skin and hair, leaving you with a radiant
      glow that emanates from within.
      <br></br>
      <br></br>
      With Kaanthi's ultimate skin and hair care, not only will you look amazing on your wedding day,
      but you'll also feel an internal glow that reflects your happiness and well-being. Trust us to help
      you achieve the perfect look for your special day.
    </p>
  </div>;
}

export default ServiceDetails