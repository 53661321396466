import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import BannerSection from '../Components/Common/BannerSection/BannerSection'
import BannerData from '../Components/Helpers/BannerData';
import ContactForm from '../Components/ContactPage/ContactForm/ContactForm';
import Footer from '../Components/Common/Footer/Footer';

function Contact() {
  const { ContactH2, ContactImg } = BannerData;

  return (
    <div className='Contact'>
        <Navbar/>
        <BannerSection h2={ContactH2} imgUrl={ContactImg}/>
        <ContactForm/>
        <Footer/>

    </div>
  )
}

export default Contact