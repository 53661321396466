import React from 'react'
import BannerData from '../Components/Helpers/BannerData'
import Navbar from '../Components/Common/Navbar/Navbar'
import BannerSection from '../Components/Common/BannerSection/BannerSection'
import ServiceDetails from '../Components/SpaServicePage/ServiceDetails/ServiceDetails'
import Footer from '../Components/Common/Footer/Footer'

function SpaServices() {
    const { SpaServicesH2, SpaServicesImg } = BannerData

  return (
    <div className='SpaServices'>
        <Navbar/>
        <BannerSection h2={SpaServicesH2} imgUrl={SpaServicesImg}/>
        <ServiceDetails/>
        <Footer/>
    </div>
  )
}

export default SpaServices